import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';

import { isValidEmail } from '../utils/helper';
import { userService } from '../services/user.service';
import SEO from '../utils/seo';

const ForgotPassword = () => {

    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [disableResetBtn, setDisableResetBtn] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
        setErrors((prevState) => {
            return {
                ...prevState,
                [name]: null
            };
        });
    }

    const isValidEmailEntry = () => {
        if (inputs.emailaddress === undefined || inputs.emailaddress === null || inputs.emailaddress.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['emailaddress']: 'Please enter email address.'
                };
            });
            return false;
        }
        if (!isValidEmail(inputs.emailaddress)) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['emailaddress']: 'Please enter a valid email address.'
                };
            });
            return false;
        }
        return true;
    }

    const handleSubmitClick = (event) => {
        event.preventDefault();
        setErrors({});
        if (!isValidEmailEntry()) {
            return;
        }
        var params = { 'email': inputs.emailaddress };        
        userService.forgotPassword(params)
        .then((res) => {
            // Popup: Please check your email to finish resetting your password. User can cross on top right
            setDisableResetBtn(true);
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['resetpwd']: res
                };
            });
        })
        .catch(error => {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['resetpwd']: error.message
                };
            });
        });
    }

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Forgot Password? Recover Your Account Access | Con10Craze"
                description="Forgot your password? Easily recover your account access on our platform. Follow the simple steps to reset your password and regain control of your account."
                type="website"
                url="https://www.con10craze.com/forgot-password" />

            <Row className='forgot-pwd-row'>
                <h4 className='forgot-pwd-heading' style={{ marginTop: "100px" }}>FORGOT PASSWORD</h4>
            </Row>

            <Container >
                <Row className='forgot-pwd-row'>
                    <input
                        type="email"
                        name="emailaddress"
                        placeholder='Enter your email address'
                        value={inputs.emailaddress}
                        onChange={handleChange} />
                </Row>
                {errors.emailaddress && <p style={{ display: "flex", color: "red", justifyContent: "center", 
                        alignItems: "center", margin: "2px" }}>{errors.emailaddress}</p>}
            
                <Row className='forgot-pwd-row'>
                    <Button type="submit"
                        className='btn-round' color='info'
                        onClick={handleSubmitClick}
                        disabled={disableResetBtn}
                        >RESET PASSWORD</Button>
                    {errors.resetpwd && <p style={{ display: "flex", color: "red", justifyContent: "center", 
                        alignItems: "center", margin: "2px" }}>{errors.resetpwd}</p>}
                </Row>

                <Row className='forgot-pwd-row'>
                    <Button className='btn-round' color='info' onClick={() => navigate('/login')}>Go back</Button>
                </Row>
            </Container>
        </div>
    );
}

export default ForgotPassword;
