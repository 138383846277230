export const faqs = [
    {
      question: "What is Con10Craze Fantasy Sport?",
      answer:
      "Con10Craze Fantasy Sport is a strategy-based online games platform where you can create a virtual team of creators. Based on the performance of these creators and the team you create, you win cash prizes and rewards."
    },
    {
      question: "Is it safe to deposit money to Con10Craze?",
      answer:
        "Adding money to your Con10Craze is both simple and safe. We have many different payment options to ensure the safety of your personal details.",
    },
    {
      question: "How to play Con10Craze Fantasy Sports Games?",
      answer: <>
        Log in on Con10Craze web portal and follow these simple steps to participate in a game:<br></br>
        1. Select the game.<br></br>
        2. Use your skills to create your team from the pool of creators.<br></br>
        3. Pay entry fee to join it.<br></br>
        4. Based on the cumulative performance of your team, check your score and earnings.</>,
    },
    {
      question: "How do I get my winnings?",
      answer: "It's a very simple process. You can submit a withdrawal request from your profile and your winnings would be credited to your account within 3 business days."
    },
    {
        question: "How is the Game score calculated?",
        answer: <>Your game's score is calculated based on the team you create and the performance of the creators in your team. A creator's score is calculated as:<br></br>
            <div style={{ fontStyle: "italic" }}>(Average Likes + 2*Comments per post)/ (Number of followers) * 100</div><br></br>
        Your score is the sum of all the creators' scores in your team. </>
    },
    {
        question: "How do I select creators?",
        answer: "You can select the creators from the pool of creators in the game to create your team. In future, Con10Craze will support various digital cards and flash cards of the creators to choose from."
    },
    {
        question: "How do I refer friends to join Con10Craze?",
        answer: <>You can find your unique referral link in your <a href="/profile" target="_blank" rel="noopener noreferrer" >Profile page</a> under Settings tab which you can share with your friends.</>
    }
  ];
