import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

function Verify() {
    const location = useLocation();
    const navigate = useNavigate();
    const [email] = useState(location.state ? location.state.email : '');

    useEffect(() => {
        if (email.trim().length === 0) {
            return (
                navigate('/')
            )
        }
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });

    if (!location.state || !location.state.email || location.state.email.trim().length === 0) {
        return (
            <Navigate to="/" replace />
        )
    }

    return (
        <div className="section section-basic" id="basic-elements">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">
                            CHECK YOUR EMAIL
                        </h2>

                        <p style={{ fontWeight: "375" }}>
                        We've sent a verification email to <span style={{fontWeight: 'bold'}}>{email}</span>.<br></br>
                        Tip: Please check your spam inbox if you can't find the mail.
                        </p>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Verify;
