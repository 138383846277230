import { Button, Container, Modal, ModalBody, Row } from "reactstrap";
import Confetti from "react-confetti";

function MyModalDialog(props) {

    return (
        <>
            <Container>
                <Row id="modal">

                    <Modal isOpen={props.show} toggle={() => props.onClose(false)}>
                        <div className="modal-header justify-content-center">
                            {!props.noCloseButton && <button
                                className="close"
                                type="button"
                                onClick={() => props.onClose(false)}
                            >
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </button>}
                            <h4 className="title title-up">{props.title}</h4>
                        </div>
                        {props.showConfetti && <Confetti height={5000} />}
                        <ModalBody>
                            {props.children}
                        </ModalBody>
                    </Modal>
                </Row>
            </Container>
        </>
    )
}

export default MyModalDialog;
