import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { userService } from "services/user.service";
import SEO from "utils/seo";

import logo from "../assets/img/logo.svg";

function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();
    const startDate = new Date(Date.parse("1 Feb 2024"));

    useEffect(() => {
        userService.getBlogs()
            .then((res) => {
                setBlogs(res);
            })
            .catch((error) => {
            })
    }, [])

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Latest Insights & Updates | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Stay up-to-date with the latest news, trends, and insights from the Entertainment industry. Explore our informative blogs covering Content Creators, Influencers, Artists, Fantasy Sports."
                type="blog"
                url="https://www.con10craze.com/blogs" />

            <Container>
                <Row>
                    {blogs.map((blog, index) => {
                        let blogDate = new Date(blog["published_at"]);
                        if (blogDate >= startDate) {
                            return (
                                <Col className="ml-auto mr-auto text-center" xs="6" md="4" onClick={(e) => navigate('/blogs/' + blog['public_name'])}>
                                    <img src={logo} width="100%" style={{ aspectRatio: 6/5 }} />
                                    <h4>{blog['title']}</h4>
                                    <p>{format(new Date(blog['published_at']), 'dd MMM, yyyy')}</p>
                                </Col>        
                            )
                        }
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default Blogs;
