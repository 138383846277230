import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PasswordChecklist from "react-password-checklist";

import { userService } from '../services/user.service';
import SEO from '../utils/seo';
import { Button, Container, Row } from 'reactstrap';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [inputs, setInputs] = useState({"password": "", "repassword": ""});
    const [errors, setErrors] = useState({});
    const [checkResetToken, setCheckResetToken] = useState(true);
    const [showResetForm, setShowResetForm] = useState(false);
    const [ isPwdValid, setIsPwdValid ] = useState(false);

    // With Strict Mode starting in React 18, whenever a component mounts in development,
    // React will simulate immediately unmounting and remounting the component. On the second mount,
    // React will restore the state from the first mount. This feature simulates user behavior such as a
    // user tabbing away from a screen and back, ensuring that code will properly handle state restoration.
    // This only applies to development mode, production behavior is unchanged.
    useEffect(() => {
        if (checkResetToken) {
            if (token === undefined || token === null || token.trim().length === 0) {
                navigate('/login');
            }
            userService.resetPassword(token)
                .then((res) => {
                    setShowResetForm(true);
                })
                .catch(error => {
                    // popup The link that you have used has either expired or is invalid.
                    //            navigate('/login');
                });
            setCheckResetToken(false);
        }
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
        setErrors((prevState) => {
            return {
                ...prevState,
                [name]: null
            };
        });
    }

    const isValidPasswordMatch = () => {
        if (inputs.password === undefined || inputs.password === null || inputs.password.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['password']: 'Please enter a valid password.'
                };
            });
            return false;
        }
        if (inputs.repassword === undefined || inputs.repassword === null || inputs.repassword.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['repassword']: 'Please confirm the password.'
                };
            });
            return false;
        }
        if (inputs.password != inputs.repassword) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['repassword']: 'Passwords do not match.'
                };
            });
            return false;
        }
        return true;
    }

    const handleSubmitClick = (event) => {
        event.preventDefault();
        if (!isValidPasswordMatch()) {
            return;
        }
        var params = { 'new_password': inputs.password, 'reset_token': token };
        userService.setNewPassword(params)
            .then((res) => {
                navigate('/', { state: res });
            })
            .catch(error => {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        ['resetpwd']: error.message
                    };
                });
            });
    }

    if (!showResetForm) {
        return (
            <div className="section section-basic" id="basic-elements">
            </div>
        );
    } else {
        return (
            <div className="section section-basic" id="basic-elements">
                <SEO
                    title="Reset Password: Create a New Secure Password | Con10Craze"
                    description="Reset your password for Con10Craze account. Follow the simple steps to create a new, strong password and regain access to your account."
                    type="website"
                    url="https://www.con10craze.com/reset-password" />

                <Row className='forgot-pwd-row'>
                    <h4 className='forgot-pwd-heading' style={{ marginTop: "100px" }}>RESET PASSWORD</h4>
                </Row>

                <Container>
                    <Row className='forgot-pwd-row'>
                        <input
                            type="password"
                            className='signup-form-input'
                            name="password"
                            placeholder="New Password"
                            value={inputs.password}
                            onChange={handleChange} />
                    </Row>
                    {errors.password && <p style={{
                            display: "flex", color: "red", justifyContent: "center",
                            alignItems: "center", margin: "2px"
                        }}>{errors.password}</p>}

                    <Row className='forgot-pwd-row'>
                        <input
                            type="password"
                            className='signup-form-input'
                            name="repassword"
                            placeholder="Confirm Password"
                            value={inputs.repassword}
                            onChange={handleChange} />
                    </Row>
                    {errors.repassword && <p style={{
                            display: "flex", color: "red", justifyContent: "center",
                            alignItems: "center", margin: "2px"
                        }}>{errors.repassword}</p>}

                    <Row className='forgot-pwd-row'>
                        <Button type="submit"
                            className='btn-round' color="info"
                            disabled={!isPwdValid}
                            onClick={handleSubmitClick}
                            >RESET PASSWORD</Button>
                        {errors.resetpwd && <p style={{
                            display: "flex", color: "red", justifyContent: "center",
                            alignItems: "center", margin: "2px"
                        }}>{errors.resetpwd}</p>}

                    <PasswordChecklist
                        rules={["minLength", "match"]}
                        minLength={8}
                        value={inputs.password}
                        valueAgain={inputs.repassword}
                        onChange={(isValid) => {setIsPwdValid(isValid)}}
                        style={{ invalidColor: "#E50914", display: "grid", justifyContent: "center", textAlign: "left", marginTop: "20px" }}
                    />

                    </Row>
                </Container>
            </div>
        );
    }
};

export default ResetPassword;
