import React, {useState} from 'react';
import {CardBody, Col, Container, Row} from 'reactstrap';

import {userService} from 'services/user.service';
import {useParams} from 'react-router-dom';
import SEO from 'utils/seo';


const instaEmbed = (embed_url, index2, index) => {
    let abcd= embed_url + "/embed/?utm_source=ig_embedembed%2F%3Fc%3D1%3B";
    let id = "instagram-embed-" + index2 + "-" + index;
    let dataInstagramPayloadId = "instagram-media-payload-" + index2 + "-" + index;;
    return (
        <>
        <a href={embed_url} target="_blank" rel="noopener noreferrer">
         <iframe className="instagram-media instagram-media-rendered"
                 loading="lazy"
                 style={{ pointerEvents: "none" }}
                 id={id}
                 data-src={abcd}
                 src={abcd} allowtransparency="true" height="595" frameBorder="0"
                 data-instagram-payload-id={dataInstagramPayloadId}
                 onClick={() => window.open(embed_url, '_blank')}

         >


         </iframe>
        </a>
        </>
    )
}

const Playground = () => {
    const [dataLoaded, setDataLoaded] = useState(0);
    const {game_external_id} = useParams();
    const [creators, setCreators] = useState([]);

    React.useEffect(() => {
        userService.getFantasyGameCreatorsDetails(game_external_id)
          .then((res) => {
                setCreators(res["artists"])
                setDataLoaded(1)
          }).catch((err) => { })
    }, []);    

    const [selectedArtist, setSelectedArtist] = useState('');

    const handleArtistChange = (e) => {
        setSelectedArtist(e.target.value);
    };

    const filteredCreator = selectedArtist ? creators.filter(creator => creator["name"] === selectedArtist) : creators;

    const contentLayout = () => {
        if (!dataLoaded) {
            return <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        }

        if (dataLoaded) {
            return (
                <Container style={{padding: "0px"}}>
                    <Row>
                        <Col className="ml-auto mr-auto" xs="12" md="4" style={{padding:"0px"}}>

                            {filteredCreator.map((creator, index2) => (
                                <div>
                                    {creator["posts"].map((post, index) => (
                                            <div key={index} className="insights-grid-item"
                                                 style={{boxShadow: '0 4px 8px rgba(1, 1, 1, 1)', padding: "0px"}}>
                                                    {instaEmbed(post.embed_url, index2, index)}
                                            </div>
                                    ))}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Container>)
        }
    }


    return (
        <div className="section section-tabs" id="tabs-elements">
            <SEO
                title="Watch Live Content and Insights of Game Creators!"
                description="Watch live action of Con10Craze's Fantasy Sports Games and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores. Let the games begin!"
                type="website"
                url={"https://con10craze.com/playground/" + game_external_id} />

            <Container>

                <Row>
                    <Col className="ml-auto mr-auto">
                        {/*<h2 className="title center">Check all the posts part of this game</h2>*/}
                        {/*<h5 className="title center" style={{ margin:"auto",marginBottom:"10px",color:"red"}}>Click here for quick stats</h5>*/}
                        <h3 className="title">GAME CONTENT</h3>


                        <CardBody>
                            {contentLayout()}
                        </CardBody>
                    </Col>

                </Row>


            </Container>
        </div>
    )
}

export default Playground;
