import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { userService } from "services/user.service";


const insights = [
    { icon: "now-ui-icons business_chart-bar-32", name: 'Number of Games involved in', key: 'num_games', color: "blue" },
    { icon: "now-ui-icons users_single-02", name: 'Selected by Players', key: 'teams', color: "orange" },
    { icon: "now-ui-icons business_chart-pie-36", name: 'Number of Games won', key: 'games_won', color: "red" },
    { icon: "now-ui-icons sport_trophy", name: 'Earnings from Games', key: 'earnings', color: "green" },
];

const ArtistInsights = () => {
    const [userData, setUserData] = useState(userService.getLocalStorageData());
    const [insightsData, setInsightsData] = useState({});
    const [instaHandle, setInstaHandle] = useState(userData.insta_handle);
    const [data, setData] = useState([]);
    const [instaHandleSet, setInstaHandleSet] = useState( userData.insta_handle && userData.insta_handle.length > 0 ? 1 : 0);

    useEffect(() => {
        userService.getArtistInsights()
            .then((res) => {
                let data = {};
                if ("num_games" in res) {
                    data["num_games"] = res["num_games"];
                }
                if ("games_won" in res) {
                    data["games_won"] = res["games_won"];
                }
                if ("earnings" in res) {
                    data["earnings"] = res["earnings"];
                }
                if ("teams" in res) {
                    data["teams"] = res["teams"];
                }
                setInsightsData(data);
                console.log(res)
                if (res['table']) {
                    setData(res['table']);
                }
            })
            .catch((err) => { })
    }, []);

    return (
        <div>
            <Container>
                <Row>
                    {insights.map((insight, index) => (
                        <Col className="ml-auto mr-auto" sm="6">
                            <div key={index} className="insights-grid-item">
                                <i className={insight.icon} style={{ color: insight.color }} ></i>
                                <div className="insight-details">
                                    <div className="insight-name">{insight.name.toUpperCase()}</div>
                                    <div className="insight-value" style={{ color: insight.color }}>{insightsData[insight.key]}</div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

                <Row style={{backgroundColor: "transparent", padding: "0px", marginTop: "25px", marginLeft: "0px"}}>
                            <input style={{
                                borderRadius: "15px",
                                border: "1px solid #b7b7b7",
                                padding: "10px",
                                transition: "0.2s",
                            }} placeholder="Your Instagram handle" value={instaHandle}
                                   disabled />
                        </Row>

                <h3 style={{ marginTop: "50px", marginBottom: "0px" }}>My Creator Games</h3>
                <Table borderless style={{ display: "block", overflow: "auto" }}>
                    <thead style={{ backgroundColor: "#320544", color: "white" }}>
                        <tr>
                            <th>
                                Game
                            </th>
                            <th>
                                My Engagement Score
                            </th>
                            <th>
                                Earnings
                            </th>
                            <th>
                                Game Time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((rowData, index) => {
                            return (
                                <tr>
                                    <th scope="row">
                                        {rowData.game}
                                    </th>
                                    <td>
                                        {parseFloat(rowData.score).toFixed(2)}
                                    </td>
                                    <td>
                                        ₹{rowData.earnings}
                                    </td>
                                    <td>
                                        {rowData.game_time}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default ArtistInsights;