import { useState } from "react";
import { Alert, Button, Col, Container, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";
import { userService } from "services/user.service";
import { isValidEmail } from "utils/helper";
import SEO from "utils/seo";

function ContactUs() {
    const [inputs, setInputs] = useState({'profession': "Player"});
    const [errors, setErrors] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (event, name) => {
        const value = event.target.value;
        setInputs((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
        setErrors((prevState) => {
            return {
                ...prevState,
                [name]: null
            };
        });
    }

    const isValidEmailEntry = () => {
        if (inputs.emailaddress === undefined || inputs.emailaddress === null || inputs.emailaddress.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['emailaddress']: 'Please enter email address.'
                };
            });
            return false;
        }
        if (!isValidEmail(inputs.emailaddress)) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['emailaddress']: 'Please enter a valid email address.'
                };
            });
            return false;
        }
        return true;
    }

    const handleSubmitClick = (event) => {
        event.preventDefault();
        if (inputs.profession === undefined || inputs.profession === null) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['profession']: 'Please select one option.'
                };
            });
            return;
        }

        if (!isValidEmailEntry()) {
            return;
        }

        if (inputs.subject === undefined || inputs.subject === null || inputs.subject.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['subject']: 'Please enter subject.'
                };
            });
            return;
        }

        if (inputs.message === undefined || inputs.message === null || inputs.message.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['message']: 'Please enter message.'
                };
            });
            return;
        }

        var params = { 'email': inputs.emailaddress, 'subject': inputs.subject, 'message': inputs.message, 'profession': inputs.profession };
        userService.contactus(params)
            .then(() => {
                setShowAlert(true);
                setInputs({});
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        ['contactus']: null
                    };
                });
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
            .catch(error => {
                let errorMsg = "Sorry, something went wrong. Please try again later!";
                if (typeof (error) !== 'object') {
                    errorMsg = error.message;
                }

                setErrors((prevState) => {
                    return {
                        ...prevState,
                        ['contactus']: errorMsg
                    };
                });
            });
    }

    return (
        <div className="section section-tabs" id="tabs-elements">
            <SEO
                title="Reach Out to Us | Con10Craze"
                description="Have questions or feedback? Contact our team at contact-us@con10craze.com. We are here to assist you."
                type="website"
                url="https://www.con10craze.com/contact-us" />

            <Container >
                <Row>
                    <Col className="pull-left" md="8">
                    {showAlert &&
                        <div style={{ marginBottom: "25px" }}>
                            <button type="button" class="close" data-dismiss="alert" onClick={() => setShowAlert(false)}>&times;</button>
                            We have received your request and will be in touch with you shortly.
                        </div>
                    }

                        <Form>

                            <InputGroup className={"no-border input-lg"}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons users_circle-08"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Enter your email address"
                                    type="email"
                                    value={inputs.emailaddress}
                                    onChange={e => handleChange(e, "emailaddress")}
                                ></Input>
                            </InputGroup>                            
                            <Alert color="danger" isOpen={errors.emailaddress !== null && errors.emailaddress !== undefined}>
                                    <Container>
                                        {errors.emailaddress}
                                    </Container>
                                </Alert>

                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    placeholder="Subject"
                                    type="text"
                                    value={inputs.subject}
                                    onChange={e => handleChange(e, "subject")}
                                ></Input>
                            </InputGroup>
                            <Alert color="danger" isOpen={errors.subject !== null && errors.subject !== undefined}>
                                    <Container>
                                        {errors.subject}
                                    </Container>
                                </Alert>

                            <InputGroup className={"no-border input-lg"}>
                                <Input
                                    placeholder="Message"
                                    type="textarea"
                                    value={inputs.message}
                                    rows="50"
                                    onChange={e => handleChange(e, "message")}
                                ></Input>
                            </InputGroup>
                            <Alert color="danger" isOpen={errors.message !== null && errors.message !== undefined}>
                                    <Container>
                                        {errors.message}
                                    </Container>
                                </Alert>

                            <FormGroup row>
                                <Label
                                    for="exampleSelect"
                                    sm={2}
                                >
                                    Your profession
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="exampleSelect"
                                        value={inputs.profession}
                                        name="select"
                                        type="select"
                                        onChange={e => handleChange(e, "profession")}
                                    >
                                        <option>
                                            Player
                                        </option>
                                        <option>
                                            Creator
                                        </option>
                                        <option>
                                            Artist Management Co.
                                        </option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup
                                check
                                row
                            >
                                <Row className="pull-left" md="8" style={{marginLeft:"0.5px"}}>
                                    <Col>Contact Number: </Col>
                                    <Col>+91 93807 40935</Col>
                                </Row>
                                <Col
                                    sm={{
                                        offset: 2,
                                        size: 10
                                    }}
                                >
                                    <Button className="btn-round" color="info" size="lg" onClick={handleSubmitClick} style={{marginTop:"50px"}}>
                                        Submit
                                    </Button>

                                </Col>

                            </FormGroup>
                            <Alert color="danger" isOpen={errors.contactus !== null && errors.contactus !== undefined}>
                                    <Container>
                                        {errors.contactus}
                                    </Container>
                                </Alert>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUs;
