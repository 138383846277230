/*eslint-disable*/
import React from "react";

// reactstrap components
import { Col, Container, Row } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
            <li style={{margin: 0}}>
              <a
                  href="/blogs"
                  style={{
                    display: 'inline-block',
                    padding: '12px 20px',
                    margin: '4px 0',
                    textDecoration: 'none',
                    color: '#ffffff',
                    borderRadius: '4px',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
                  onFocus={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onBlur={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
              >
                Blog
              </a>
            </li>
            <li style={{margin: 0}}>
              <a
                  href="/contact-us"
                  style={{
                    display: 'inline-block',
                    padding: '12px 20px',
                    margin: '4px 0',
                    textDecoration: 'none',
                    color: '#ffffff',
                    borderRadius: '4px',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
                  onFocus={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onBlur={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
              >
                Contact Us
              </a>
            </li>
            <li style={{margin: 0}}>
              <a
                  href="/terms-of-use"
                  target="_blank"
                  style={{
                    display: 'inline-block',
                    padding: '12px 20px',
                    margin: '4px 0',
                    textDecoration: 'none',
                    color: '#ffffff',
                    borderRadius: '4px',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
                  onFocus={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onBlur={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
              >
                Terms of Service
              </a>
            </li>
            <li style={{margin: 0}}>
              <a
                  href="/privacy-policy"
                  target="_blank"
                  style={{
                    display: 'inline-block',
                    padding: '12px 20px',
                    margin: '4px 0',
                    textDecoration: 'none',
                    color: '#ffffff',
                    borderRadius: '4px',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
                  onFocus={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                    e.currentTarget.style.color = '#0056b3';
                  }}
                  onBlur={(e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '#ffffff';
                  }}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()} {" "}
          <a
              href="https://con10craze.com"
              target="_blank"
          >
            Con10Craze Pvt Ltd.
          </a>
        </div>
      </Container>

      <Row className="notice"
           style={{wordWrap: "break-word", overflow: "clip", overflowWrap: "break-word", width: "100%"}}>
        <Col className="ml-auto mr-auto text-center">
          <span style={{wordWrap: "break-word", overflow: "clip"}}>This game may be habit-forming or financially risky.Play responsibly.
          </span>
        </Col></Row>
    </footer>
  );
}

export default DarkFooter;
