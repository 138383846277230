import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { userService } from 'services/user.service';
import { defaultProfileImage } from 'utils/constants';

import games from "../assets/img/game.jpg";
import logo from "../assets/img/logo.svg";

import SEO from 'utils/seo';
import { Col, Container, Row } from 'reactstrap';

const PublicProfile = () => {
  const { uuid } = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [isImageLoaded, setIsImageLoaded] = useState(false); // State to track image load
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    userService.getUserPublicProfile(uuid)
      .then((res) => setUserProfile(res))
      .catch((err) => { })
  }, [uuid]);

  const handleBack = () => {
    // Always try navigating back
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/'); // Fallback to homepage if no history
    }
  };

  return (
    <div>
      <SEO
        title="Your Con10Craze Profile – Showcase Your Fantasy Sports Achievements & Connect with Friends"
        description="Explore your Con10Craze public profile to track your fantasy sports achievements, game wins, referral progress, and connect with friends. Join the social sports revolution and share your journey with others!"
        type="website"
        url={"https://www.con10craze.com/user/" + uuid} />

      <div className="wrapper" style={{
        backgroundImage: `url(${games})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'
      }}>

        <Container>
          <Row>
            <Col className='ml-auto mr-auto'>


              {/* Back Button */}
              <button className="back-button-funky" onClick={handleBack}
                style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 10 }}>
                &larr; Back
              </button>

              <div className="creator-profile-funky">
                <div className="profile-header-funky">
                  {/* Use placeholder logo until the image loads */}
                  <img
                    src={isImageLoaded && userProfile.profile_image ? userProfile.profile_image : defaultProfileImage} // Show actual image once loaded
                    alt={`${userProfile.first_name}'s profile`}
                    className="profile-pic-funky"
                    onLoad={() => setIsImageLoaded(true)} // When image loads, set state to true
                  />
                  <div className="profile-info-funky">
                    <h2>{userProfile.first_name}</h2>
                    <h4>@{userProfile.uuid}</h4>
                  </div>
                </div>

                <Container className="profile-stats-extra-funky">
                  <Row>
                    <Col className="text-center ml-auto mr-auto stat-item-funky" xs="4" >
                      <div className="stat-icon-wrapper">
                        <i className="fas fa-gamepad stat-icon-funky"></i> {/* Icon for Games Played */}
                      </div>
                      <span className="stat-number-funky">{userProfile.games_played}</span>
                      <span className="stat-description-funky">Games Played</span>
                    </Col>
                    <Col className="text-center ml-auto mr-auto stat-item-funky" xs="4">
                      <div className="stat-icon-wrapper">
                        <i className="fas fa-trophy stat-icon-funky"></i> {/* Icon for Games Won */}
                      </div>
                      <span className="stat-number-funky">{userProfile.games_won}</span>
                      <span className="stat-description-funky">Games Won</span>
                    </Col>
                    <Col className="text-center ml-auto mr-auto stat-item-funky" xs="4" >
                      <div className="stat-icon-wrapper">
                        <i className="stat-icon-funky now-ui-icons objects_diamond" />
                      </div>
                      <span className="stat-number-funky">{userProfile.level}</span>
                      <span className="stat-description-funky">Level</span>
                    </Col>
                  </Row>
                </Container>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </div >

  );
};

export default PublicProfile;
