import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import SEO from '../utils/seo';
import MyProfile from './index-sections/MyProfile';
import { loginWaitTime } from 'utils/constants';
import { userService } from 'services/user.service';
import MyAccountBalance from './index-sections/MyAccountBalance';
import { NotLoggedIn } from 'utils/helper';
import ArtistInsights from './index-sections/ArtistInsights';
import AdminSection from './index-sections/AdminSection';
import { artistRoleName } from 'utils/constants';
import AffiliateSection from './index-sections/AffiliateSection';
import ArtistMgmtInsights from './index-sections/ArtistMgmtInsights';

const UserProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab] = React.useState(location.state ? "activeTab" in location.state ? location.state["activeTab"] : 0 : 0);
    const streaksRef = React.useRef(null);
    const [iconPills, setIconPills] = React.useState((0 <= activeTab && activeTab <= 4) ? activeTab : 0);
    const userData = userService.getLocalStorageData();
    const isArtist = 'role' in userData ? ((userData['role'] === artistRoleName) || (userData['role'] === "creator_affiliate") ? true: false) : false;
    const isAdmin = 'role' in userData ? (userData['role'] === "admin" ? true: false) : false;
    const isAffiliate = 'role' in userData ? ((userData['role'] === "affiliate") || (userData['role'] === "creator_affiliate") ? true: false) : false;
    const isAffiliatePartner = 'role' in userData ? (userData['role'] === "affiliate_partner" ? true: false) : false;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        if (!userService.isLoggedIn()) {
            setTimeout(() => {
                navigate('/login', {state: {'redirectTo': '/profile'}});
            },  loginWaitTime);  //5000 milliseconds
            return;
        } else {
            userService.getUserWalletDetails();
        }

    }, []);


    React.useEffect(() => {

        if (location.state?.scrollToStreaks && streaksRef.current) {
            streaksRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const handleTabChange = (e, tabIndex) => {
        e.preventDefault();
        setIconPills(tabIndex);
    };

    if ( !userService.isLoggedIn() ) {
        return NotLoggedIn();
    }

    return (
        <div className="section section-tabs" id="tabs-elements">
            <SEO
                title="User Profile | Personalized Account Details and Activity | Con10Craze"
                description="Access and manage your user profile on Con10Craze."
                type="website"
                url="https://www.con10craze.com/profile" />

            <Container>
                <Row>
                    <Col className="ml-auto mr-auto">

                        <Nav className="justify-content-center" role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "0" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "0")}
                                >
                                    Account balance
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={iconPills === "1" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "1")}
                                >
                                    Profile
                                </NavLink>
                            </NavItem>
                            {isArtist && <NavItem>
                                <NavLink
                                    className={iconPills === "2" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "2")}
                                >
                                    Insights
                                </NavLink>
                            </NavItem>}
                            {isAdmin && <NavItem>
                                <NavLink
                                    className={iconPills === "3" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "3")}
                                >
                                    Admin
                                </NavLink>
                            </NavItem>}
                            {isAffiliate && <NavItem>
                                <NavLink
                                    className={iconPills === "4" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "4")}
                                >
                                    Affiliate Stats
                                </NavLink>
                            </NavItem>}
                            {isAffiliatePartner && <NavItem>
                                <NavLink
                                    className={iconPills === "5" ? "active" : ""}
                                    onClick={(e) => handleTabChange(e, "5")}
                                >
                                    Affiliate Partner Stats
                                </NavLink>
                            </NavItem>}
                        </Nav>


                        <CardBody>
                            <TabContent
                                activeTab={"iconPills" + iconPills}
                            >
                                <TabPane tabId="iconPills0">
                                    <MyAccountBalance streaksRef={streaksRef} />
                                </TabPane>
                                <TabPane tabId="iconPills1">
                                    <MyProfile />
                                </TabPane>
                                {isArtist && <TabPane tabId="iconPills2">
                                    <ArtistInsights />
                                </TabPane>}
                                {isAdmin && <TabPane tabId="iconPills3">
                                    <AdminSection />
                                </TabPane>}
                                {isAffiliate && <TabPane tabId="iconPills4">
                                    <AffiliateSection />
                                </TabPane>}
                                {isAffiliatePartner && <TabPane tabId="iconPills5">
                                    <ArtistMgmtInsights />
                                </TabPane>}
                            </TabContent>
                        </CardBody>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default UserProfile;
