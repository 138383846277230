import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "reactstrap";

import {userService} from "services/user.service";
import {getCurrentDateInIST} from "utils/helper";
import MyModalDialog from "./MyModalDialog";
import streak from "../../assets/img/streak.png";
import { StreakBonus } from "utils/constants";

const Streaks = () => {
    const userData = userService.getLocalStorageData();
    let todayDate = getCurrentDateInIST();
    todayDate.setHours(0,0,0,0);

    const [streakData, setStreakData] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        fetchStreakData().then(r => {
        });
    }, []);

    const fetchStreakData = async () => {
        let streaks = {};
        var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
        var lastDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth()+1, 0);

        for (var d = firstDay; d <= lastDayOfMonth; d.setDate(d.getDate() + 1)) {
            streaks[d] = false;
        }

        if (!userService.isLoggedIn()) {
            setStreakData(streaks);
            return;
        }

        userService.getMyStreaks()
            .then((res) => {
                res.map((item, index) => {
                    let dd = new Date(item['date']);
                    dd = getCurrentDateInIST(dd);
                    dd.setHours(0, 0, 0, 0);
                    streaks[dd] = true;
                })
                setStreakData(streaks);
            })
            .catch((err) => {
            })

    };

    const handleStreakClick = (day) => {
        if (!userService.isLoggedIn()) {
            return;
        }
        if (streakData[day] === true) {
            return;
        }
        if (new Date(day).getTime() != todayDate.getTime()) {
            return;
        }

        let newStreakData = { ...streakData };
        newStreakData[day] = true;
        setStreakData(newStreakData);


        userService.updateTodayStreakRewards()
            .then((res) => {
                // notify user of their new reward
                userService.updateLastStreakTime(todayDate);
                setIsDialogOpen(true)
            })
            .catch((err) => {
            })
    };

    const showStreakCell = (streakDate, index) => {
        return <div
            key={index}
            className={`streak-card ${streakData[streakDate] === true ? 'clicked' : ''} ${new Date(streakDate).getDay() === 0 ? 'sunday' : ''}`}
            style={new Date(streakDate).getTime() >= todayDate.getTime() && streakData[streakDate] !== true ? {backgroundColor: "#2196f3"} : {}}
            onClick={() => handleStreakClick(streakDate)}
        >
            <div className="card-content">
                <span>Day {new Date(streakDate).getDate()}</span>
            </div>
        </div>
    }


    const notifyStreakChallengeComplete = () => {
        return (
             <div className={`streak-dialog ${isDialogOpen ? 'open' : ''}`}>
                <MyModalDialog title="Daily Streak" showConfetti='true' show={isDialogOpen}
                               onClose={(e) => setIsDialogOpen(false)}>
                    <div className="streak-content">
                        <img
                            src={streak}
                            width='100'
                            height='95'
                            alt="Daily streak"
                        />
                        <p>Awesome, you got your cash bonus! Track your streaks to earn extra weekly bonus.</p>
                    </div>
                </MyModalDialog>
            </div>
        )
    }

    const popupNotifications = () => {
        return (
            <>
                {notifyStreakChallengeComplete()}
            </>
        )
    }

    return (
        <>
            {popupNotifications()}
            <div className="section section-basic" id="basic-elements">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center">
                            <div className="daily-streaks">
                                <h3>Earn Bonus from Daily Streaks</h3>
                                <p>Bonus earned so far: <span style={{ fontWeight: "bold" }}>₹{'bonus' in userData ? userData['bonus'] : 0}</span></p>                                
                                <p>💰 Daily Reward: <span style={{ fontWeight: "bold" }}>₹{StreakBonus}</span></p>
                                <p>🏆 Reward on Weekly streak: <span style={{ fontWeight: "bold" }}>₹15</span></p>
                                <p>Click daily to maintain your streak!</p>
                                <div className="streak-card-container">
                                    {Object.keys(streakData).map((streakDate, index) => (
                                        showStreakCell(streakDate, index)

                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Streaks;
