import {useEffect} from 'react';
import { useNavigate} from 'react-router-dom';

import SEO from '../utils/seo';
import { userService } from '../services/user.service';
import { isValidString } from '../utils/helper';
import { Container, Row } from 'reactstrap';

const LoginSuccess = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const authData = query.get("auth");
        const redirectTo = query.get("redirectTo");

        const data = {"auth_data": authData};
        userService.completeLoginGoogleUser(data)
        .then((res) => {
            setTimeout(() => {
                if (isValidString(redirectTo)) {
                    navigate(redirectTo, {state: res});
                } else {
                    navigate('/', {state: res});
                }
            }, 3000);  //3000 milliseconds
            return;
        })
        .catch((err) => {
        })
    }, [])

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Successfully logged in | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Access your account and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores!"
                type="website"
                url="https://www.con10craze.com/login-success" />

            <Container>
                <Row style={{ height: "50px" }}></Row>

                <Row className='signup-row'>
                    <h1 style={{ color: "white", justifyContent: "center", textAlign: "center" }}>You've successfully logged in!</h1>
                </Row>

            </Container>

        </div>
    );
}

export default LoginSuccess;
