import {Button, Col, Container, Row} from "reactstrap";
import Accordion from "./index-sections/Accordion";
import SEO from "utils/seo";
import React from "react";
import how_to_thumb from "../assets/img/how_to_thumb.png";
import {useNavigate} from "react-router-dom";






const howToPlayVideoSrc = "https://d78eqrbdvopvl.cloudfront.net/how_to_play.mp4";
const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    flexWrap: 'wrap', // Allow wrapping of divs
    padding: "5px",
};

const divStyles = {
    position: 'relative',
    width: '100%',
    height: '140px',
    backgroundColor: '#f0f0f0', // Grey background color
    border: '1px solid #dddddd',
    borderRadius: '10px', // Rounded corners
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginBottom:"20px",// Margin between divs for spacing
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" /* Shadow for 3D effect */
};

const numberCircleStyles = {
    position: 'absolute',
    top: '-15px', // Adjust to be half inside and half outside
    left: '15px', // Adjust to be half inside and half outside
    width: '30px',
    height: '30px',
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    border: '1px solid #dddddd',
};


const containerStylesPoint = {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    width: "100%",
    margin: "auto",
    borderRadius:"5px",
    boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.1)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "14px",
};

const infoCardStyles = {
    // backgroundColor: '#f5f5f5',
    // border: '1px solid #dddddd',
    // borderRadius: '10px',
    margin: '10px',

    width: '100%',
    textAlign: 'center',
};

const headerStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '20px',
    padding: "5px"
};

const detailStyles = {
    fontSize: '16px',
    color: '#4D4D4d',
    marginBottom: '10px',
    padding: "10px"

};

const formulaStyles = {
    fontSize: '18px',
    //    color: '#4CAF50',
    fontWeight: 'bold',
    marginBottom: '20px',
};


const ScoreInformation = () => {
    return (
        <div style={containerStylesPoint}>
            <div style={infoCardStyles}>
                <div style={headerStyles}><p style={{ fontSize: "18px", fontWeight: "bold" }}>How Your Score is Calculated</p></div>
                <div style={detailStyles}>Each Like: <span style={{ color: '#008000', fontWeight: 'bold' }}>1 point</span></div>
                <div style={detailStyles}>Each Comment: <span style={{ color: '#008000', fontWeight: 'bold' }}>2 points</span></div>
                <div style={formulaStyles}>
                    Creator Score = <br />
                    (1*<span style={{ color: '#008000' }}>{'Likes'}</span> + 2*<span style={{ color: '#008000' }}>{'Comments'}</span>) / <span style={{ color: '#008000' }}>{'Followers'}</span>*100
                </div>
                <div style={detailStyles}>
                    Your score is calculated as the sum of scores of the creators in your team.
                </div>
            </div>
        </div>
    );
};


const howToSection = () => {
    return <div className="section section-basic" id="basic-elements" style={{padding: "5px"}}>
        <Row>
            <Col className="ml-auto mr-auto text-center" style={{padding: "5px"}}>
                <h2 className="title">
                    HOW TO PLAY
                </h2>
                <p>
                    Watch this video to learn how to play on Con10Craze and get started today!<br></br><br></br>
                    {/*Create your team of favourite Instagram creators, compete based on their engagement, and earn cash rewards. Visit the playground for insider tips on creators and boost your chances of winning. Play, compete, and cash in!<br></br><br></br>*/}
                    {/*Are you ready to challenge fellow sports fans?*/}
                </p>
                <video id="teaser_vid"
                       preload="none"
                       frameBorder="0"
                       poster={how_to_thumb}
                       playsInline
                       controls
                       width="100%"
                       title="How to Play Con10Craze Fantasy Sports"
                       style={{borderRadius: "10px", paddingLeft:"20px", paddingRight:"20px"}}
                >
                    <source src={howToPlayVideoSrc} type="video/mp4"/>
                </video>
            </Col>
        </Row>

        {/*<Row>*/}
        {/*    <Col className="text-center ml-auto mr-auto" lg="6" md="8" style={{padding: "5px"}}>*/}
        {/*        <Button*/}
        {/*            className="btn-round"*/}
        {/*            color="info"*/}
        {/*            style={{fontWeight: "bold"}}*/}
        {/*            onClick={(e) => {*/}
        {/*                e.preventDefault();*/}
        {/*                navigate("/fantasy-games")*/}
        {/*            }}*/}
        {/*            size="lg"*/}
        {/*        >*/}
        {/*            Start Playing Now!*/}
        {/*        </Button>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
    </div>
}



const scoringSection = () => {
    return <div>
        <Container>
            <Row>
                <Col className="text-center ml-auto mr-auto" style={{padding: "15px"}}>
                    <ScoreInformation/>
                </Col>
            </Row>
        </Container>
    </div>
}


const stepsToPlay = () => {
    return <div className="section section-basic" id="basic-elements" style={{    color: "black",padding: "5px" }}>
        <Row>
            <Col className="ml-auto mr-auto text-center">

                <h2 className="title">
                    It's super easy to start playing on <span className="text-logo">Con10Craze</span>
                </h2>
                <Row>
                    <div style={containerStyles}>
                        <div style={containerStyles}>
                            <Col className="ml-auto mr-auto" xs="12" md="4">
                                <div className="free-text-content" key="1" style={divStyles}>
                                    <div style={numberCircleStyles}>1</div>
                                    <Col style={{marginLeft: "10px"}}>
                                        <Row style={{color: "black", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>
                                            <h2 className="title">Select a Game</h2>
                                        </Row>
                                        <Row style={{textAlign: "left"}}>
                                            <p>Choose upcoming game that you want to play</p>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>

                            <Col className="ml-auto mr-auto" xs="12" md="4">
                                <div className="free-text-content" key="2" style={divStyles}>
                                    <div style={numberCircleStyles}>2</div>
                                    <Col style={{marginLeft: "10px"}}>
                                        <Row style={{color: "black", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>
                                            <h2 className="title">Create your Team</h2>
                                        </Row>
                                        <Row style={{textAlign: "left"}}>
                                            <p>Use your skills to pick the right creators</p>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>

                            <Col className="ml-auto mr-auto" xs="12" md="4">
                                <div className="free-text-content" key="3" style={divStyles}>
                                    <div style={numberCircleStyles}>3</div>
                                    <Col style={{marginLeft: "10px"}}>
                                        <Row style={{color: "black", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>
                                            <h2 className="title">Participate</h2>
                                        </Row>
                                        <Row style={{textAlign: "left"}}>
                                            <p>Lock in your team and win cash rewards</p>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>

                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
    </div>
}




function HowTo() {
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

    })

    return (  
            <div className="section section-basic" id="basic-elements">
                <SEO
                title="How to Play Con10Craze Fantasy Sports: Video Guide, Scoring Algorithm, and Quick Steps"
                description="Learn how to play Con10Craze Fantasy Sports with our step-by-step video guide. Understand the scoring algorithm and follow quick steps to start building your dream team and earning money. Master the game in minutes!"
                type="website"
                url="https://www.con10craze.com/how-to" />

              <Container>
                  {stepsToPlay()}
                  {howToSection()}
                  {scoringSection()}
            </Container>
        </div>
    )
}

export default HowTo;
