import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { userService } from 'services/user.service';
import SEO from 'utils/seo';
import games from "../assets/img/game.jpg";
import logo from "../assets/img/logo.svg";
import { Col, Container, Row } from "reactstrap"; // Import the logo as the placeholder
import { Comment as CommentIcon,  ThumbUp as ThumbUpIcon,  Person as PersonIcon, Star as StarIcon} from '@mui/icons-material';

const CreatorProfile = () => {
    const { social_media_handle } = useParams();
    const [creator, setCreator] = useState({ 'posts': [], 'weekly_score':'0,0,0,0,0,0,0' });
    const [isImageLoaded, setIsImageLoaded] = useState(false); // State to track image load
    const [isLoaded, setIsLoaded] = useState(false); // State to track image load
    const navigate = useNavigate(); // Initialize navigate
    const [images, setImages] = useState([]);

    useEffect(() => {
        userService.getCreatorDetails(social_media_handle)
            .then((res) => {
                setCreator(res)
                setIsLoaded(true)
                fetchImages(res);
            })
            .catch((err) => {});
    }, [social_media_handle]);

    const fetchImages = async (creator_data) => {
        const imageUrls = await Promise.all(
            creator_data["posts"].map(async (post) => {
            // Fetch the image using your middleware
            if (post.thumbnail_url) {
                const imageBlobUrl = await userService.getInstagramPostThumbnail(post.thumbnail_url);
                return imageBlobUrl;
            } else {
                return null;
            }
          })
        );
        setImages(imageUrls); // Update state with fetched image URLs
    };

    const styles = {
        section1: {
            padding: "20px",
            color: "black",
            display: "flex",
            alignItems: "center",
        },
        profileImage: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginRight: "15px",
        },
        section2: {
            backgroundColor: "#f5f5f5",
            margin: "20px auto",
            padding: "20px",
            borderRadius: "15px",
            width: "90%",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
        histogramContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            height: "200px", // Maximum height for the histogram
            padding: "10px",
        },
        histogramBar: {
            position: "relative", // For placing the number above
            borderRadius: "5px",
        },
        section3: {
        },
        postContainer: {
            backgroundColor: "grey",
            borderRadius: "15px",
            padding: "15px",
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
        },
        postTop: {
            display: "flex",
            alignItems: "center",
        },
        thumbnail: {
            width: "60px",
            height: "60px",
            borderRadius: "10px",
            marginRight: "15px",
        },
        statsRow: {
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px",
        },
        statItem: {
            textAlign: "center",
        },
    };


    // Helper function to format followers count
    const formatFollowers = (count) => {
        if (!count){
            count = 0;
        }
        else if (count >= 1_000_000) {
            return (count / 1_000_000).toFixed(1) + "M"; // Converts to millions
        } else if (count >= 1_000) {
            return (count / 1_000).toFixed(1) + "K"; // Converts to thousands
        }
        return count.toString(); // Less than 1K, return as is
    };


    const handleBack = () => {
        // Always try navigating back
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/'); // Fallback to homepage if no history
        }
    }

    const section1 = () => {
        const handleBack = () => {
            window.history.back(); // or your navigation logic
        };

        return (
            <div style={{ position: 'relative' }}>
                {/* Back Button */}
                <button className="back-button-funky" onClick={handleBack}
                        style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 10 }}>
                    &larr; Back
                </button>

                <Row
                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${games})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        color: 'white',
                        padding: '20px',
                        position: 'relative', // Ensures that the profile image and text are placed correctly below the button
                    }}
                >
                    <div style={{ marginRight: '20px' }}>
                        <img
                            src={creator.profile_img}
                            alt={`${creator.name}'s profile`}
                            className="profile-pic-funky"
                            onLoad={() => setIsImageLoaded(true)}
                            onError={() => setIsImageLoaded(false)} // Optionally handle error if image fails to load
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '3px solid white',
                                visibility: isImageLoaded ? 'visible' : 'hidden', // Hide image until it's loaded
                                marginTop: '40px', // Add margin to push the profile image down a bit
                            }}
                            loading="lazy" // Use lazy loading to improve performance
                        />
                        {!isImageLoaded && (
                            <img
                                src={logo} // Display the fallback logo image if profile image isn't loaded
                                alt="Profile Image Placeholder"
                                className="profile-pic-funky"
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    border: '3px solid white',
                                }}
                            />
                        )}
                    </div>

                    {/* User Details */}
                    <div style={{ marginTop: '40px' }}> {/* Add margin to avoid overlapping */}
                        {/* User Name */}
                        <h2 style={{ margin: '0', marginTop: '5px', fontWeight: 'bold', color: '#FFD700' }}>
                            {creator.name}
                        </h2>

                        {/* Instagram Handle */}
                        <a href={"https://instagram.com/" + creator.social_media_handle} target="_blank"
                           style={{
                               textDecoration: 'none',
                               display: 'inline-block',
                               background: 'linear-gradient(45deg, #FF3B30, #FF6F1F, #FF9F00, #FFB74D, #FF6F00)',
                               WebkitBackgroundClip: 'text',
                               color: 'transparent',
                               fontWeight: 'bold',
                               fontSize: '14px',
                               margin: '5px 0',
                           }}
                        >
                            @{creator.social_media_handle}
                        </a>

                        {/* Followers */}
                        <p style={{ fontSize: '14px', margin: '5px 0', fontWeight: 'bold', color: '#FFD700' }}>
                            {formatFollowers(creator.followers)} Followers
                        </p>
                    </div>
                </Row>
            </div>
        );
    };



    const section2 = () => {
        if (!isLoaded) {
            return <></>;
        }

        const scores = creator['weekly_scores'].split(',').map(Number); // Convert string to an array of numbers
        const maxScore = Math.max(...scores);
        const minBarHeight = 1; // Set a minimum bar height for zero scores

        return (
            <Row style={{ ...styles.section2, textAlign: "center" }}>
                <h3 style={{ fontWeight: "bolder", marginBottom: "20px", color: "#FF6347" }}>
                    Last Week Score
                </h3>
                <Col
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        padding: "0 20px",
                    }}
                >
                    {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => {
                        const score = scores[index];
                        const barHeight = score === 0 ? minBarHeight : (score / maxScore) * 100;

                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "12%",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: `rgba(${255 - barHeight}, ${barHeight}, ${barHeight * 2}, 0.8)`,
                                        width: "30px",
                                        height: `${barHeight}px`,
                                        borderRadius: "5px",
                                        marginBottom: "5px",
                                        position: "relative",
                                    }}
                                >
                                <span
                                    style={{
                                        position: "absolute",
                                        top: "-20px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        color: "#333",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {score === 0 ? "" : score.toFixed(1)}
                                </span>
                                </div>
                                <p style={{ marginTop: "5px", fontWeight: "bold", color: "#FF6347" }}>{day}</p>
                            </div>
                        );
                    })}
                </Col>
            </Row>
        );
    };


    const formatTimestamp = (timestamp) => {
        // Check if timestamp is a valid date
        const date = new Date(timestamp);

        // If the date is invalid, fallback to the current date
        if (isNaN(date)) {
            return new Date().toLocaleString('en-US', {
                month: 'short', // "Nov"
                day: 'numeric', // "24"
                hour: 'numeric', // "12"
                minute: 'numeric', // "34"
                hour12: true, // AM/PM format
            });
        }

        // If the date is valid, return formatted timestamp
        const options = {
            month: 'short', // "Nov"
            day: 'numeric', // "24"
            hour: 'numeric', // "12"
            minute: 'numeric', // "34"
            hour12: true, // AM/PM format
        };

        return date.toLocaleString('en-US', options);
    };



    const section3 = () => {
        const calculateScore = (likes, comments) => {
            return ((likes + 2 * comments) * 100 / creator.followers).toFixed(2); // Score formula
        };

        return (
            <Row
                style={{
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    marginLeft: "20px", // Add space on the left
                    marginRight: "20px", // Add space on the right
                }}
            >
                <div
                    style={{
                        width: "100%", // Take full width of the container
                        maxWidth: "1200px", // Limit the maximum width for readability
                        margin: "0 auto", // Center the content horizontally
                    }}
                >
                    <h3
                        style={{
                            width: "100%",
                            textAlign: 'center',
                            marginTop: '20px',
                            marginBottom: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        Posts Analysis
                    </h3>
                    <div
                        style={{
                            scrollbarWidth: "none",
                            maxHeight: "500px", // Limit height for scrolling
                            overflowY: "auto", // Enable vertical scrolling
                            padding: "10px",
                            borderRadius: "10px",
                        }}
                    >
                        {creator['posts'].map((post, index) => {
                            const imageSrc = images[index]; // Get the image URL from the state

                            return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: '15px',
                                    padding: '15px',
                                    backgroundColor: '#F9F9F9',
                                    boxShadow: "0 4px 6px -4px rgba(0, 0, 0, 0.1)",
                                    border: "1px solid #DDD",
                                    borderRadius: '10px',
                                }}
                            >
                                {/* Top Section */}
                                <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {/* Thumbnail */}
                                        <img
                                            src={imageSrc || logo}
                                            alt="Post Thumbnail"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                borderRadius: '10px',
                                                marginRight: '15px',
                                                marginLeft: '15px',
                                                objectFit: 'cover', // Ensures the image fits well within the container
                                                backgroundColor: '#f0f0f0', // Add a background in case the image fails to load
                                            }}
                                            onError={(e) => (e.target.src = logo)} // Replace with logo if the image fails to load*/}
                                        />
                                        {/* Caption */}
                                        <p
                                            style={{
                                                margin: '0',
                                                fontSize: '14px',
                                                color: '#000',
                                                fontStyle: 'italic',
                                                overflow: 'hidden', // Hide overflowing text
                                                textOverflow: 'ellipsis', // Add ellipsis at the end
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                            }}
                                            title={post['caption']} // Show full text on hover
                                        >
                                            <a
                                                href={post['embed_url'] || '#'} // Ensure fallback URL in case embed_url is not available
                                                target="_blank" // Open in a new tab
                                                rel="noopener noreferrer" // Security best practice
                                                style={{
                                                    textDecoration: 'underline', // Underline the link
                                                    color: '#1E90FF', // Set the link color to blue
                                                }}
                                            >
                                                {post['caption'] || 'Post Title'}
                                            </a>
                                        </p>

                                        {/* Timestamp */}
                                        <p
                                            style={{
                                                margin: '0',
                                                fontSize: '12px',
                                                color: '#777',
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            {formatTimestamp(post.post_timestamp)}
                                        </p>
                                    </div>
                                </Row>
                                {/* Divider Line */}
                                <hr style={{ margin: '10px 0', borderColor: '#DDD' }} />
                                {/* Statistics */}
                                <Row style={{ textAlign: 'center' }}>
                                    <Col xs="4">
                                        <CommentIcon style={{ color: '#42A5F5', fontSize: '24px' }} />
                                        <p style={{ margin: '0', fontSize: '12px', color: '#42A5F5' }}>Comments</p>
                                        <p style={{ margin: '0', fontWeight: 'bold', color: '#1E88E5' }}>
                                            {formatNumber(post.num_comments)}
                                        </p>
                                    </Col>
                                    <Col xs="4">
                                        <ThumbUpIcon style={{ color: '#66BB6A', fontSize: '24px' }} />
                                        <p style={{ margin: '0', fontSize: '12px', color: '#66BB6A' }}>Likes</p>
                                        <p style={{ margin: '0', fontWeight: 'bold', color: '#43A047' }}>
                                            {formatNumber(post.num_likes)}
                                        </p>
                                    </Col>
                                    <Col xs="4">
                                        <StarIcon style={{ color: '#FFD700', fontSize: '24px' }} />
                                        <p style={{ margin: '0', fontSize: '12px', color: '#FFD700' }}>Score</p>
                                        <p style={{ margin: '0', fontWeight: 'bold', color: '#FFC107' }}>
                                            {calculateScore(post.num_likes, post.num_comments)}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            )
    })}
                    </div>
                </div>
            </Row>
        );
    };

    // Helper function to format numbers to K, M, etc.
    const formatNumber = (num) => {
        if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
        if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
        return num;
    };


    return (
        <div>
            <SEO
                title={"Discover " + creator.name + " | Con10Craze Creator Profile, Stats, and Social Links"}
            description={"Explore the official profile of " + creator.name + ", a top Fantasy Sports Creator on Con10Craze. View their stats, including followers, average likes, and comments. Check out their wins and games played, and connect with them on Instagram for more exciting content!"}
            type="website"
            url={"https://www.con10craze.com/creator/" + creator.name}
        />
        <Col className="ml-auto mr-auto">
            {section1()}

            {section2()}

            {section3()}
        </Col>
        </div>
    );
};

export default CreatorProfile;
