import {useEffect, useState} from 'react';
import useTheme from "@mui/material/styles/useTheme";
import { useSearchParams} from 'react-router-dom';

import SEO from '../utils/seo';
import { Container, Row } from 'reactstrap';

const LoginFailed = () => {

    const theme = useTheme();
    const [msg, setMsg] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const failureMsg = searchParams.get("status");
        if (failureMsg) {
            setMsg(failureMsg);
            searchParams.delete('status');
            setSearchParams(searchParams);
          }
        return;
    }, [])

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Log in failed | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Access your account and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores!"
                type="website"
                url="https://www.con10craze.com/login-failed" />

            <Container>
                <Row style={{ height: "50px" }}></Row>

                <Row className='signup-row'>
                    <h2 style={{ color: "white", marginTop: "50px", marginBottom: "50px", justifyContent: "center", textAlign: "center" }}>{msg}</h2>
                </Row>

            </Container>

        </div>
    );
}

export default LoginFailed;
