import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { userService } from "services/user.service";

function VerifyEmail() {
    const navigate = useNavigate();
    const { token } = useParams();

    // With Strict Mode starting in React 18, whenever a component mounts in development,
    // React will simulate immediately unmounting and remounting the component. On the second mount,
    // React will restore the state from the first mount. This feature simulates user behavior such as a
    // user tabbing away from a screen and back, ensuring that code will properly handle state restoration.
    // This only applies to development mode, production behavior is unchanged.
    useEffect(() => {
        if (userService.isLoggedIn()) {
            navigate('/');
            return;
        }
        if (token === undefined || token === null || token.trim().length === 0) {
            navigate('/');
        }
        userService.verifyEmail(token)
        .then((res) => {
            navigate(0);
        })
        .catch(error => {
            alert(error);
            navigate('/');
        });
    });
}

export default VerifyEmail;
