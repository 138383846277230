import SEO from '../utils/seo';
import { Container, Row } from 'reactstrap';

const PrivacyPolicy = () => {

    return (
  
            <div className="section section-basic" id="basic-elements">
            <SEO
                title="Privacy Policy | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Privacy Policy For Using Our Fantasy Sports Website"
                type="website"
                url="https://www.con10craze.com/privacy-policy" />

            <Container>
                <Row style={{ height: "50px" }}></Row>

                <Row>
                    <h1 style={{ fontWeight: "bold", fontSize: "larger" }}>
                        Privacy Policy
                    </h1>

                    <p>

                    At Con10Craze, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our fantasy sports platform in India. By accessing or using Con10Craze, you agree to the terms of this Privacy Policy.<br></br><br></br>

                    Before you submit any information to the Portal, please read this Privacy Policy for an explanation of how we will treat your information. By using any part of the Portal, you consent to the collection, use, disclosure and transfer of your information for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of this information. Your use of any part of the Portal indicates your acceptance of this Privacy Policy and of the collection, use and disclosure of your information in accordance with this Privacy Policy. While you have the option not to provide us with certain personal information, withdraw your consent to collect certain information, request temporary suspension of collection of personal information or request deletion of personal information collected, kindly note that in such an event you may not be able to take full advantage of the entire scope of features and services offered to you and we reserve the right not to provide you with our services.


<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>Information We Collect</h2>

<p>
When you use Con10Craze, we collect the following types of information:

Personal Information: We may collect personal information such as your name, email address, username, and state of residence. This information is collected for registration and account management purposes.

Public Information: We may use publicly available information about artists and creators from social media platforms. This information helps us create and curate content for our fantasy sports platform.
<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>How We Use Your Information</h2>

<p>
We use the information we collect for various purposes, including:
<ul>
<li>Providing and maintaining our fantasy sports platform.</li>
<li>Personalizing your user experience and customizing the content you see.</li>
<li>Communicating with you, including sending important updates, notifications, and marketing communications.</li>
<li>Analyzing user engagement and behavior to improve our platform's performance and features.</li>
<li>Ensuring compliance with legal and regulatory requirements.</li>
</ul>
<br></br></p>

<h2 style={{ fontWeight: "bold" }}>Information Sharing</h2>

<p>
We may share your information with trusted third-party service providers who assist us in operating our platform, conducting business activities, or providing services on our behalf. These service providers are contractually obligated to maintain the confidentiality of your information and use it only for the purposes specified by Con10Craze.<br></br><br></br>
By using the Portal, you hereby expressly agree and grant consent to the collection, use and storage of your information by Con10Craze. Con10Craze reserves the right to share, disclose and transfer information collected hereunder with its affiliates and group entities. In the event Con10Craze sells or transfers all or a portion of its business assets, consumer information may be one of the business assets that are shared, disclosed or transferred as part of the transaction. You hereby expressly grant consent and permission to Con10Craze for disclosure and transfer of personal information to such third parties.<br></br><br></br>

Con10Craze may share information and data concerning usage of the Services and participation in the Game with its commercial partners for the purpose of facilitating user engagement, for marketing and promotional purposes and other related purposes. Further, Con10Craze reserves the right to disclose/share information with affiliates, group entities and third parties in limited circumstances, including for the purposes of complying with applicable law, responding to duly authorized legal process, governmental requests, preventing fraud or imminent harm, and ensuring the security of our network and services.

<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>
Use of Cookies
</h2>

<p>
To improve the effectiveness and usability of the Portal for our Users, we use "cookies", or such similar electronic tools to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified device. Unless the User voluntarily identifies themselves (e.g., through registration), Con10Craze has no way of knowing who the User is, even if we assign a cookie to the User's device. The only information a cookie can contain is information supplied by the User. A cookie cannot read data off the User's device hard drive. Con10Craze's advertisers may also assign their own cookies to the User's device (if the User clicks on their ad banners), a process that Con10Craze does not control.<br></br><br></br>

Con10Craze's web servers automatically collect limited information about User's device connection to the Internet, including User's IP address, when the User uses the Portal. (User's IP address is a number that lets devices attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. Con10Craze uses this information to deliver its web pages to Users upon request, to tailor its Portal to the interests of its users, to measure traffic within the Portal and let advertisers know the geographic locations from where Con10Craze's visitors come.


<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>Advertising</h2>

<p>
When Con10Craze presents information to its online advertisers - to help them understand its audience and confirm the value of advertising on the Portal - it is usually in the form of aggregated anonymized data (information that cannot be used to trace a user) of Users that may include statistics and/or data concerning User traffic, User responsiveness and User behaviour to such advertisements on our Portal. When you register with Con10Craze, we contact you from time to time about updating your content to provide features which we believe may benefit you.


<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>Conditions of Use</h2>
<p>
CON10CRAZE DOES NOT WARRANT THAT THIS PORTAL, IT’S SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. CON10CRAZE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PORTAL, INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL CON10CRAZE BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR 100.


<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>Data Security</h2>

<p>
We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We employ industry-standard security technologies and procedures to safeguard your data.

<br></br><br></br></p>

<h2 style={{ fontWeight: "bold" }}>Data Retention</h2>

<p>
We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When we no longer need your information, we will securely delete or anonymize it to prevent unauthorized access.

<br></br><br></br></p>
<h2 style={{ fontWeight: "bold" }}>Your Rights</h2>

<p>
You have certain rights regarding your personal information, including the right to access, correct, or delete your data. You may also request that we restrict the processing of your information or object to its processing. To exercise these rights or inquire about our data practices, please contact us using the information provided below.

<br></br><br></br></p>
<h2 style={{ fontWeight: "bold" }}>Contact Us</h2>
<p>
If you have any questions, concerns, or feedback about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:contact-us@con10craze.com">contact-us@con10craze.com</a>. We are committed to addressing your inquiries promptly and transparently.

<br></br><br></br></p>
<h2 style={{ fontWeight: "bold" }}>Changes to This Policy</h2>

<p>
We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically for the latest information on our data practices.

<br></br><br></br></p>

<p>
Thank you for trusting Con10Craze with your personal information. We are dedicated to protecting your privacy and providing you with a safe and enjoyable user experience.
<br></br><br></br></p>
                </Row>

            </Container>

            </div>
    );
}

export default PrivacyPolicy;
