import ReactGA from 'react-ga';
import React from 'react';
import DarkFooter from 'components/Footers/DarkFooter';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import LandingPageHeader from 'components/Headers/LandingPageHeader';

ReactGA.initialize("AW-10873845405");

const PartialPageLayout = ({ page: Page }) => {

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <LandingPageHeader />
                <div className="main"></div>
                <Page />
                <DarkFooter />
            </div>
        </>
    )
}

export default PartialPageLayout;