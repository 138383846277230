import React from "react";
import Slider from 'react-slick';
import {useViewPort} from "../../utils/helper";
import {useNavigate} from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import banner2 from "./../../assets/img/multi-team.webp"
import banner3 from "./../../assets/img/private-banner.webp"
import banner1 from "./../../assets/img/hero-banner.webp"

// core components

function BannerPageHeader() {

    const banners = [banner1, banner2, banner3]
    const bannersName = ["Con10craze Fantasy Sports of Instagram", "Supporting Multi-teams in Fantasy Sports", "Create your own private contests and compete with friends"]
    const { width } = useViewPort();
    const breakpoint = 700;
    const navigate = useNavigate();

    const handleBannerClick = (event) => {
        event.preventDefault();
        navigate({ pathname: '/fantasy-games'});
    }


    const bannerActions = [handleBannerClick, handleBannerClick, handleBannerClick]


    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        gap:"10px",
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    dots: false,
                    infinite: true,
                    autoplay: true,
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 3000,
                }
            }
        ]
    };

    return (
        <Container>
            <Row>
                <Col className="ml-auto mr-auto">
                    <div  style={{position:"relative", overflow:"hidden" }} >
                        <div style={{ marginTop: "160px" }}>
                            <Slider {...settings}>
                                {banners.map((banner, index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center", // Center the banner in its container
                                            }}
                                            onClick={(e) => bannerActions[index](e)}
                                        >
                                            <img
                                                style={{
                                                    imageRendering: "crisp-edges",
                                                    height:(width>breakpoint) ? "auto":"250px", // Let the height adjust automatically
                                                    maxWidth: "100%", // Make sure the image scales down on smaller screens
                                                    padding: "0 5px", // Optional: Extra padding around the image for more space
                                                }}
                                                src={banner} // Ensure this is a valid image URL
                                                alt={bannersName[index]}
                                            />
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default BannerPageHeader;
