import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { Col, Container, Row } from "reactstrap";
import parse from 'html-react-parser';

import { userService } from "services/user.service";
import SEO from "utils/seo";

function BlogPage() {
    const { public_name } = useParams();
    const [blog, setBlog] = useState({});
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const social_hashtag = "con10craze";
    const social_body = "";

    useEffect(() => {
        userService.getBlogData(public_name)
            .then((res) => {
                setBlog(res);
                setTitle(res['title'] + " | Con10Craze Fantasy Sports");
                setUrl("https://con10craze.com/blogs/" + res['public_name']);
            })
            .catch((error) => {
            })
    }, [])

    const socialMediaShare = () => {
        return (
            <div>
                <Row style={{ marginTop: "20px", marginBottom: "30px", marginLeft: "0px", justifyContent: "left", alignItems: "left" }}>
                    <FacebookShareButton className='react_share_button' style={{ width: "auto" }}
                        url={url}
                        title={social_body}
                    >
                        <FacebookIcon size={36} round={true} />
                    </FacebookShareButton>

                    <TwitterShareButton className='react_share_button' style={{ width: "auto" }}
                        url={url}
                        title={title}
                        hashtags={[social_hashtag]}
                    >
                        <TwitterIcon size={36} round={true} />
                    </TwitterShareButton>

                    <LinkedinShareButton className='react_share_button' style={{ width: "auto" }}
                        url={url}
                    >
                        <LinkedinIcon size={36} round={true} />
                    </LinkedinShareButton>

                    <RedditShareButton className='react_share_button' style={{ width: "auto" }}
                        url={url}
                        title={social_body}
                    >
                        <RedditIcon size={36} round={true} />
                    </RedditShareButton>

                    <WhatsappShareButton className='react_share_button' style={{ width: "auto" }}
                        url={url}
                        title={social_body}
                        separator=":: "
                    >
                        <WhatsappIcon size={36} round={true} />
                    </WhatsappShareButton>

                </Row>
            </div>
        )
    }

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title={title}
                description="Dive into our in-depth blog posts, articles, and analysis on the Entertainment industry. Explore our informative blogs covering Content Creators, Influencers, Artists, Fantasy Sports."
                type="blog"
                url={"https://www.con10craze.com/blogs/" + public_name} 
                image={blog['cover_pic_url']} />

            <Container>
                <Row>
                    <Col className="pull-left">

                        {blog['title'] && <h2>{blog['title']}</h2>}

                        {blog['title'] && <p>{format(new Date(blog['published_at']), 'dd MMM, yyyy')}</p>}
                        {blog['title'] && socialMediaShare()}
                        {blog['title'] && <div>{parse(blog['content'])}</div>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BlogPage;
