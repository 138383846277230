import ReactGA from 'react-ga';
import { userService } from "services/user.service";
export function loginConversionTag(){
    var dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('event', 'conversion', {'send_to': 'AW-11139362279/OUUPCJ2G6rgZEOfb1L8p'})
}

export function gameJoinedConversionTag(game_payment){
    var dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('event', 'conversion', {'send_to': 'AW-11139362279/OUUPCJ2G6rgZEOfb1L8p'})
    gtag('event', 'conversion', {
        'send_to': 'AW-11139362279/UqBHCInB7bgZEOfb1L8p',
        'value': game_payment,
        'currency': 'INR',
        'transaction_id': ''
    });
}

function getRequestSourcePixel() {
    var requestSource = userService.getSearchParamsKeyFromCookie("refId");
    if (requestSource === null || requestSource === undefined) {
        requestSource = "organic";
    }
    return requestSource;
}

export function pixelPurchaseConversionTracking(amount){
    if (window.fbq === undefined || window.fbq === null) {
        return;
    }
    return window.fbq('trackCustom', 'CustomPurchaseEvent', {
        con10crazesource: getRequestSourcePixel(),  // Custom parameter
        currency: "INR",              // Optional standard parameter
        value: amount                  // Optional standard parameter
    });
}


export function pixelSignupConversionTracking(){
    if (window.fbq === undefined || window.fbq === null) {
        return;
    }
    return window.fbq('trackCustom', 'CustomSignupEvent', {
        con10crazesource: getRequestSourcePixel(),  // Custom parameter
    });
}



export function pixelCreatorRegisterConversionTracking(){
    if (window.fbq === undefined || window.fbq === null) {
        return;
    }
    return window.fbq('trackCustom', 'CustomCreatorRegisterEvent', {
        con10crazesource: getRequestSourcePixel(),  // Custom parameter
    });
}



export function pixelDepositConversionTracking(amount){
    if (window.fbq === undefined || window.fbq === null) {
        return;
    }
    return window.fbq('trackCustom', 'CustomDepositEvent', {
        con10crazesource: getRequestSourcePixel(),  // Custom parameter
        currency: "INR",              // Optional standard parameter
        value: amount                  // Optional standard parameter
    });
}



export function useGAEventsTracker(category = "Event Category") {
    const trackEvent = ( action = "action", label = "label") => {
        ReactGA.event({ category, action, label });
    };
    return trackEvent;
}