import React, { useState, useEffect } from 'react';

const FunkyProgressBar = ({ val, maxVal , liveUpdate, levels, showCheckpoints }) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const [progress, setProgress] = useState(0); // Initial progress value
    const progress2 = (val / maxVal) * 100;
    const progressLevels = (levels && levels.length > 1) ? levels : [0,20,40,60,80,100];
    const levelJump = 100/(progressLevels.length - 1);

    // Simulate progress increasing over time for demo purposes
    useEffect(() => {
        if (liveUpdate) {
            const interval = setInterval(() => {
                setProgress((prev) => (prev < val-1 ? prev + 2 : prev));
            }, 1000); // Update progress every second
            return () => clearInterval(interval);
        }
        else {
            setProgress(val);
        }
    }, []);

    return (
        <>
            {liveUpdate && <div className="funky-progress-runner" style={{ left: `${progress}%` }}>
                🏃‍♂️
            </div>}
            <div className="funky-progress-container">
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${progress}%` }}
                    >
                    </div>
                </div>
                <div className="levels">
                    {progressLevels.map((progressLevel, index) => {
                        return (
                            <span className={progress >= 20 ? 'active' : ''}>
                                {formatter.format(progressLevel)}
                            </span>
                        )
                        }
                    )}
                </div>
                {showCheckpoints && <div className="checkpoints">
                    <span className={progress >= 20 ? 'active' : ''}>
                        20
                    </span>
                    <span className={progress >= 50 ? 'active' : ''}>
                        50
                        {/*            <small className="checkpoint-text">50%</small>*/}
                    </span>
                    <span className={progress >= 80 ? 'active' : ''}>
                        80
                    </span>
                    <span className={progress >= 100 ? 'active' : ''}>
                        100
                    </span>
                </div>}
            </div>
        </>

    );
};

export default FunkyProgressBar;
