import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { userService } from "services/user.service";

const ArtistMgmtInsights = () => {
    const [insightsData, setInsightsData] = useState([]);

    useEffect(() => {
        userService.getArtistMgmtInsights()
            .then((res) => {
                setInsightsData(res);
            })
            .catch((err) => {})
    }, []);

    return (
        <div>
            <Container>
                <h3 style={{ marginTop: "50px", marginBottom: "0px" }}>My Creator's Insights</h3>
                
                <Table borderless style={{ display: "block", overflow: "auto" }}>
                    <thead style={{ backgroundColor: "#320544", color: "white" }}>
                        <tr>
                            <th>
                                Creator
                            </th>
                            <th>
                                Teams created by referrals
                            </th>
                            <th>
                                Total Game Price
                            </th>
                            <th>
                                Amount paid by referrals
                            </th>
                            <th>
                                Creator's Commission
                            </th>
                            <th>
                                My Commission
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {insightsData.map((rowData, index) => {
                            return (
                                <tr>
                                    <th scope="row">
                                        {rowData.name}
                                    </th>
                                    <td>
                                        {rowData.participations}
                                    </td>
                                    <td>
                                        ₹{rowData.creator_revenue}
                                    </td>
                                    <td>
                                        ₹{rowData.referred_payment}
                                    </td>
                                    <td>
                                        ₹{rowData.creator_commission}
                                    </td>
                                    <td>
                                        ₹{rowData.your_commission}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default ArtistMgmtInsights;
