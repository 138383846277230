import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import SEO from '../utils/seo';
import { userService } from '../services/user.service';
import { isValidString } from '../utils/helper';

const GLoginCallback = () => {

    const navigate = useNavigate();
    const [msg, setMsg] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get("code");
        const state = searchParams.get("state");
        if (!code || !state) {
            return;
        }
        userService.loginGCallback(code, state)
        .then((res) => {
            let redirectTo = state;
            searchParams.delete('code');
            searchParams.delete('state');
            searchParams.delete('scope');
            searchParams.delete('authuser');
            searchParams.delete('prompt');
            setSearchParams(searchParams);
            if (isValidString(redirectTo)) {
                navigate(redirectTo, {state: res});
            } else {
                navigate('/', {state: res});
            }
        })
        .catch((error) => {
            if(typeof(error) === 'object') {
                if (error.message) {
                    setMsg(error.message);
                } else {
                    setMsg("Sorry, something went wrong. Please try again later!");
                }
            } else {
                setMsg(error);
            }
            searchParams.delete('code');
            searchParams.delete('state');
            searchParams.delete('scope');
            searchParams.delete('authuser');
            searchParams.delete('prompt');
            setSearchParams(searchParams);
        })
    }, [])

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Secure Log in to Your Account | Con10Craze Fantasy Sports | Create Dream Teams, Engage with Artists, Earn Real Money"
                description="Access your account and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores!"
                type="website"
                url="https://www.con10craze.com/g/login/callback" />

            <Container>
                <Row style={{ height: "50px" }}></Row>

                <Row className='signup-row'>
                    <h2 style={{ color: "white", marginTop: "50px", marginBottom: "50px", justifyContent: "center", textAlign: "center" }}>{msg}</h2>
                </Row>

            </Container>

        </div>
    );
}

export default GLoginCallback;
