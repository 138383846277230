import React, { useEffect, useState } from 'react';
import { userService } from 'services/user.service';
import SEO from 'utils/seo';
import banner from "../assets/img/coins.webp"
import { useViewPort } from "../utils/helper";
import IndexNavbar from "../components/Navbars/IndexNavbar";
import DarkFooter from 'components/Footers/DarkFooter';

const CoinsPage = () => {
    const [userStats, setUserStats] = useState(null);
    const { width } = useViewPort();
    const breakpoint = 700;
    const [isLoggedIn, setIsLoggedIn] = useState(userService.isLoggedIn());

    useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        userService.getCoinStats()
            .then((res) => {
                setUserStats(res);
            })
            .catch((err) => { })
    }, []);

    const earnMethods = [
        {
            title: "Referrals",
            description: "Earn coins by referring friends to Con10Craze.",
            steps: [
                "1 referral: 10 coins",
                "2 referrals: 20 coins",
                "5 referrals: 60 coins",
                "10 referrals: 140 coins",
                "20 referrals: 300 coins"
            ],
            progress: userStats && userStats.referrals ? userStats.referrals : 0,
            icon: "👥" // Can replace this with real icons
        },
        {
            title: "Private Contests",
            description: "Create private contests with your friends and earn coins.",
            steps: [
                "Create 1 private contest with minimum 5 friends: 20 coins",
                "Create 2 private contests with minimum 5 friends: 50 coins",
                "Create 5 private contests with minimum 5 friends: 150 coins",
                "Create 10 private contests with minimum 5 friends: 325 coins"
            ],
            progress: userStats && userStats.contestsCreated ? userStats.contestsCreated : 0,
            icon: "🎮"
        },
        /*{
            title: "Winning Games",
            description: "Earn coins by winning games on Con10Craze.",
            steps: [
                "Win 1 game: 10 coins",
                "Win 2 games: 20 coins",
                "Win 5 games: 60 coins",
                "Win 10 games: 135 coins",
                "Win 20 games: 290 coins",
            ],
            progress: userStats ? userStats.gameWins : 0,
            icon: "🏆"
        }*/
    ];

    const StepItem = ({ step, isCompleted, isNext }) => {
        let stepStyle = {};
        let icon = null;

        if (isCompleted) {
            stepStyle = { backgroundColor: '#d4edda', color: '#155724' }; // Green for completed
            icon = '✔️'; // White tick for completed steps
        } else if (isNext) {
            stepStyle = {
                backgroundColor: '#ffeeba', // Light yellow
                color: '#856404', // Dark yellow
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // Shadow for 3D look
                border: '1px solid #ffc107', // Border color
                borderRadius: '5px', // Rounded corners
            };
        } else {
            stepStyle = { backgroundColor: '#e2e3e5', color: '#6c757d' }; // Grey for future
        }

        // Split the step into action and coins
        const [action, coins] = step.split(/:\s*(.+)/); // Split on the first colon
        const [coinValue, coinLabel] = coins.split(" "); // Separate number from the word "coins"

        return (
            <li style={{ ...stepStyle, padding: '10px', marginBottom: '5px', borderRadius: '5px', fontSize: '16px' }}>
                {icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
                {action}:
                <span style={{ fontWeight: 'bold', color: 'black', marginLeft: '5px' }}>{coinValue}</span>
                <span style={{ marginLeft: '5px' }}>{coinLabel}</span>
            </li>
        );
    };


    const Banner = () => {
        return (
            <div style={{ marginTop: "160px" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the banner in its container
                    }}
                // onClick={(e) => bannerActions[index](e)}
                >
                    <img
                        style={{
                            imageRendering: "crisp-edges",
                            height: (width > breakpoint) ? "auto" : "250px", // Let the height adjust automatically
                            maxWidth: (width > breakpoint) ? "60%" : "100%", // Make sure the image scales down on smaller screens
                            padding: "0 0px", // Optional: Extra padding around the image for more space
                        }}
                        src={banner} // Ensure this is a valid image URL
                        alt="Earn coins on Con10Craze"
                    />
                </div>
            </div>
        );
    };


    return (
        <>            
            <IndexNavbar animate={false} />
            <div className="wrapper">
                <div className="coins-page">
            <SEO
                title="Earn Coins on Con10Craze | Referral Rewards, Private Contests & More"
                description="Discover how you can earn coins on Con10Craze by referring friends, creating private contests, and more. Track your progress and unlock rewards as you engage."
                type="website"
                url="https://www.con10craze.com/coins" />

                <Banner />
                <div className="main"></div>
                <h2 className="title">Earn Coins on Con10Craze</h2>
                <p style={{ color: "green", fontWeight: "bold" }}>
                    "Take on exciting challenges, complete simple tasks, and watch your coins stack up!"
                </p>

                <div className="earn-methods" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {earnMethods.map((method, index) => {
                        return (
                            <div className="earn-card" key={index} style={{
                                border: '2px solid #eee',
                                borderRadius: '10px',
                                padding: '20px',
                                width: '300px',
                                margin: '10px',
                                textAlign: 'left',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            }}>
                                <h2 className="text-center">{method.icon} {method.title}</h2>
                                {/* Style for description */}
                                <p style={{
                                    fontSize: '16px',               // Slightly larger font size
                                    fontStyle: 'italic',            // Italic font style for emphasis
                                    color: '#555',                  // Darker shade for better visibility
                                    padding: '10px',                // Padding for spacing
                                    marginBottom: '15px'            // Space below description
                                }}>
                                    {method.description}
                                </p>

                                <ul style={{ listStyle: 'none', padding: '0' }}>
                                    {method.steps.map((step, stepIndex) => (
                                        <StepItem
                                            key={stepIndex}
                                            step={step}
                                            isCompleted={stepIndex < method.progress}
                                            isNext={stepIndex === method.progress}
                                        />
                                    ))}
                                </ul>

                                {isLoggedIn && userStats && (
                                    <p style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        color: '#333',
                                        textAlign: 'center',
                                        marginTop: '10px'
                                    }}>
                                        Your Progress: <span
                                            style={{ color: '#28a745' }}>{method.progress}</span> / {method.steps.length}
                                    </p>
                                )}
                            </div>
                        );
                    })}
                </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default CoinsPage;
