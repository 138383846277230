import { Col, Container } from "reactstrap";
import Accordion from "./index-sections/Accordion";
import SEO from "utils/seo";
import React from "react";

function Faqs() {
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

    })
    
    return (  
            <div className="section section-basic" id="basic-elements">
                <SEO
                title="Frequently Asked Questions | Play Con10Craze Fantasy Sports Games: Earn Real Money!"
                description="Find answers to commonly asked questions about our Con10Craze Fantasy Sports Platform. Earn real money by achieving the best engagement scores. Let the games begin!"
                type="website"
                url="https://www.con10craze.com/faqs" />

              <Container>
  
                <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">
                        Frequently Asked Questions
                    </h2>
                </Col>
                <Accordion />

            </Container>
        </div>
    )
}

export default Faqs;
