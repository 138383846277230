import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PasswordChecklist from "react-password-checklist";
import { Button, Container, Row } from 'reactstrap';

import { userService } from '../services/user.service';
import SEO from '../utils/seo';
import { loginWaitTime } from '../utils/constants';
import MyModalDialog from './index-sections/MyModalDialog';

const ChangePassword = ({showChangePwd, setShowChangePwd}) => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({"password": "", "repassword": ""});
    const [errors, setErrors] = useState({});
    const [ isPwdValid, setIsPwdValid ] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
        setErrors((prevState) => {
            return {
                ...prevState,
                [name]: null
            };
        });
    }

    const isValidPasswordMatch = () => {
        if (inputs.oldpassword === undefined || inputs.oldpassword === null || inputs.oldpassword.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['oldpassword']: 'Please enter your current password.'
                };
            });
            return false;
        }
        if (inputs.password === undefined || inputs.password === null || inputs.password.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['password']: 'Please enter a valid password.'
                };
            });
            return false;
        }
        if (inputs.repassword === undefined || inputs.repassword === null || inputs.repassword.trim().length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['repassword']: 'Please confirm the password.'
                };
            });
            return false;
        }
        if (inputs.password != inputs.repassword) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    ['repassword']: 'Passwords do not match.'
                };
            });
            return false;
        }
        return true;
    }

    const handleSubmitClick = (event) => {
        event.preventDefault();
        if (!isValidPasswordMatch()) {
            return;
        }
        var params = { 'old_password': inputs.oldpassword, 'new_password': inputs.password };
        userService.changePassword(params)
            .then((res) => {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        ['changepwd']: "Your password has been changed successfully. Please login again with your new password."
                    };
                });
                setTimeout(function () {
                    userService.logout();
                    navigate('/login', { state: {"redirectTo": "/"} });
                }, loginWaitTime);
            })
            .catch(error => {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        ['changepwd']: error.message
                    };
                });
            });
    }

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="User Profile | Personalized Account Details and Activity | Con10Craze"
                description="Access and manage your user profile on Con10Craze."
                type="website"
                url="https://www.con10craze.com/change-password" />

            <MyModalDialog title="CHANGE PASSWORD" show={showChangePwd} onClose={() => setShowChangePwd(false)}>

            <Container >
                <Row className='forgot-pwd-row'>
                    <input
                        type="password"
                        className='signup-form-input'
                        name="oldpassword"
                        placeholder="Current Password"
                        value={inputs.oldpassword}
                        onChange={handleChange} />                    
                </Row>
                {errors.oldpassword && <p style={{
                        display: "flex", color: "red", justifyContent: "center",
                        alignItems: "center", margin: "2px"
                    }}>{errors.oldpassword}</p>}

                <Row className='forgot-pwd-row'>
                    <input
                        type="password"
                        className='signup-form-input'
                        name="password"
                        placeholder="New Password"
                        value={inputs.password}
                        onChange={handleChange} />
                </Row>
                {errors.password && <p style={{
                        display: "flex", color: "red", justifyContent: "center",
                        alignItems: "center", margin: "2px"
                    }}>{errors.password}</p>}

                <Row className='forgot-pwd-row'>
                    <input
                        type="password"
                        className='signup-form-input'
                        name="repassword"
                        placeholder="Confirm Password"
                        value={inputs.repassword}
                        onChange={handleChange} />                    
                </Row>
                {errors.repassword && <p style={{
                        display: "flex", color: "red", justifyContent: "center",
                        alignItems: "center", margin: "2px"
                    }}>{errors.repassword}</p>}

                <Row className='forgot-pwd-row'>
                    <Button className="btn-round" color="info" type="submit"
                        disabled={!isPwdValid}
                        onClick={handleSubmitClick}
                        >CHANGE PASSWORD</Button>
                </Row>
                {errors.changepwd && <p style={{
                        display: "flex", color: "green", justifyContent: "center",
                        alignItems: "center", margin: "2px"
                    }}>{errors.changepwd}</p>}

                <Row className='forgot-pwd-row'>
                    <PasswordChecklist
                        rules={["minLength", "match"]}
                        minLength={8}
                        value={inputs.password}
                        valueAgain={inputs.repassword}
                        onChange={(isValid) => {setIsPwdValid(isValid)}}
                        style={{ invalidColor: "#E50914", display: "grid", justifyContent: "center", textAlign: "left", marginTop: "20px" }}
                    />
                </Row>
            </Container>
            </MyModalDialog>
        </div>
    );
};

export default ChangePassword;
