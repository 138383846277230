import React, { useEffect, useState } from 'react';
import { userService } from 'services/user.service';
import { getCurrentDateInIST } from 'utils/helper';
import MyModalDialog from './MyModalDialog';
import { showTodayStreakDialog } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import streak from "../../assets/img/streak.png";

const StreakDialog = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showTrack, setShowTrack] = useState(false);
    const [streakConfetti, setStreakConfetti] = useState(false);
    const navigate = useNavigate();

    const todayDate = getCurrentDateInIST();
    todayDate.setHours(0,0,0,0);

    // Check if the streak needs to be completed today
    const showStreakDialog = showTodayStreakDialog();

    useEffect(() => {
        setIsDialogOpen(showStreakDialog);
    }, []);

    const completeStreak = () => {
        userService.updateTodayStreakRewards()
        .then((res) => {
            userService.updateLastStreakTime(todayDate);
            // notify user of their new reward
            setShowTrack(true);
            setStreakConfetti(true);
        })
        .catch((err) => { })
    };

    const trackNow = () => {
        setIsDialogOpen(false);
        navigate("/promotions");
    }

    const handleCross = () => {
        setIsDialogOpen(false);
        userService.updateStreakCross(todayDate);
    }

    return (
        <div style={{boxShadow:"0 4px 6px -4px rgba(0, 0, 0, 0.1)",borderBottom:"2px solid #dddddd"}} className={`streak-dialog ${isDialogOpen ? 'open' : ''}`}>
            <MyModalDialog title="Daily Streak" showConfetti={streakConfetti} show={isDialogOpen} onClose={handleCross}>
                <div className="streak-content">
                    <img
                        src={streak}
                        width='100'
                        height='95'
                        alt="Daily streak"
                    />
                    {showTrack && <p>Awesome, you got your cash bonus! Track your streaks to earn extra weekly bonus.</p>}
                    {showTrack && <button onClick={trackNow}>Track</button>}

                    {!showTrack && <p>Click to complete today's streak and earn cash bonus!</p>}
                    {!showTrack && <button onClick={completeStreak}>CLAIM REWARD</button>}
                </div>
            </MyModalDialog>
        </div>
    );
};

export default StreakDialog;